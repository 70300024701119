import React, { useState, useCallback, useReducer, useRef, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';
import { initialCategories } from './initialCategories.js';

function categoriesReducer(state, action) {
  switch (action.type) {
    case 'TOGGLE_PRODUCT': {
      const { categoryName, productName } = action.payload;
      return state.map((category) =>
        category.name === categoryName
          ? {
              ...category,
              products: category.products.map((product) =>
                product.name === productName
                  ? { ...product, selected: !product.selected }
                  : product
              ),
            }
          : category
      );
    }
    default:
      return state;
  }
}

function App() {
  // Updated data structure with categories containing their own products


  const categoryRefs = useRef({});  

  const [openCategory, setOpenCategory] = useState('Soft Drinks');

  const [categories, dispatch] = useReducer(categoriesReducer, initialCategories);

  const [errorMessage, setErrorMessage] = useState(null);

  console.log("Categories: ", categories)

  const [formData, setFormData] = useState({ name: '', email: '', phone: '', machine: '', notes: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPrices, setShowPrices] = useState(false);

  useEffect(() => {
    // Check the URL to set showPrices state
    setShowPrices(window.location.pathname.includes('/full-product-list'));
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProductSelect = useCallback((categoryName, productName) => {
    dispatch({
      type: 'TOGGLE_PRODUCT',
      payload: { categoryName, productName },
    });
  }, []);

    const handleCategoryClick = (categoryName) => {
    // Set the opened category
    setOpenCategory(openCategory === categoryName ? null : categoryName);
    

    setTimeout(() => {
      categoryRefs.current[categoryName]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    , 100);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setIsSubmitting(true);

    let newErrorMessage = '';
    if (!formData.name) {
      newErrorMessage += 'Please enter your name. ';
    }
    if (!formData.email) {
      newErrorMessage += 'Please enter your email. ';
    }
    if (!formData.machine) {
      newErrorMessage += 'Please enter your machine name. ';
    }
    if (newErrorMessage) {
      setErrorMessage(newErrorMessage);
      setIsSubmitting(false);
      return;
    }

    // Get all selected products from all categories
    const selectedProducts = categories
      .flatMap(category => category.products)
      .filter(product => product.selected)
      .map(product => ({ name: product.name}));

    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      machine: formData.machine,
      notes: formData.notes,
      products: selectedProducts.map((product) => `\n • ${product.name}`).join(''),
    };

    emailjs
      .send('service_garbzsx', 'template_0thjxbj', templateParams, '_tE4AgmWewBp4ExNb')
      .then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setIsSubmitted(true);
          setIsSubmitting(false);
        },
        (error) => {
          console.error('FAILED...', error);
          setIsSubmitting(false);
        }
      );
  };

const DisplayProduct = ({ product, categoryName, onSelect }) => {
  return (
    <div key={product.name} className="flex items-center p-1 border border-black rounded-sm bg-white">
      <img 
        src={product.image} 
        alt={product.name} 
        className="w-20 h-20 min-w-20 mx-1 object-contain" 
        loading="lazy" 
      />
      <div className="flex-grow p-2">
        <h3 className="text-lg">{product.name}</h3>
        {showPrices && <p className="text-sm">${product.cost.toFixed(2)}</p>}
      </div>
      <input
        type="checkbox"
        checked={product.selected}
        onChange={() => onSelect(categoryName, product.name)}
        // Apply custom styling for the checkbox
        className="ml-2 h-6 w-6 m-3 min-h-6 min-w-6 accent-yellow-300 text-black border-2 border-black rounded"
      />
    </div>
  );
};


  return (
    <div className="w-full mx-auto">
      {/* Banner with XVEND Logo */}
      <header className="flex gap-2 items-center w-full justify-between py-4 bg-gray-900">
        <div className='flex gap-2 items-center'>
        <img src="/xvend-logo.png" alt="XVEND Logo" className="h-16 pl-4" />
        <p className='text-lg sm:text-2xl text-white font-bold pl-4'>Product Requests</p>
        </div>
        <button
          onClick={() => window.open('https://xvend.com.au', '_blank')}
        className='bg-xvend-yellow text-black font-bold w-32 mr-4 border border-black rounded-sm py-2 px-4'>Main Site</button>
      </header>

      {/* Form to collect user information */}
      <div className="mb-4 p-4 rounded-sm border border-black m-4 bg-xvend-yellow">
        <p className="text-lg font-bold mb-2">
Welcome to the XVEND Product Request Form!
        </p>
        <p className="text-base mb-4">

Please browse through the list below and select the products you'd like to request for your vending machine. After making your selections, kindly provide your contact details and any additional notes or special requests you may have. Our team will reach out to you shortly to confirm your request.

        </p>
        <p className="text-base">
Thank you for choosing XVEND – we look forward to serving you!

        </p>        
      </div>

      {/* Display products under categories */}
      {categories.map((category) => (
      <div className='p-4' key={category.name}>
        <div className='flex gap-2'
        onClick={() => handleCategoryClick(category.name)}
        ref={(el) => (categoryRefs.current[category.name] = el)}
        >
        <h2 className="text-2xl pb-2 font-semibold">{category.name} ({category.products.length})</h2>
        {/* Down chevron if category is show, otherwise show up icon */}
        {/* On click, set the show category state to this title, but if it already is, then set it to null */}
        <button>
          {openCategory == category.name ? <ChevronUpIcon className="w-6 h-6" /> : <ChevronDownIcon className="w-6 h-6" />}
        </button>
        </div>
        {openCategory == category.name && (
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {category.products.map((product) => (
            <DisplayProduct
              key={product.name}
              product={product}
              categoryName={category.name}
              onSelect={handleProductSelect}
            />
          ))}
        </div>
        )}
        </div>
      ))}

      {/* Summary Section */}
      <div className="mt-8 p-4 rounded-sm bg-xvend-yellow m-4 pb-0 border border-black">
        <h2 className="text-2xl font-semibold pb-4">Summary (Your Requested Items)</h2>
        <ul>
          {categories
        .flatMap(category => category.products)
        .filter(product => product.selected).map((product) => (
            <>
            <DisplayProduct
              key={product.name}
              product={product}
              categoryName={categories.find(cat => cat.products.includes(product)).name}
              onSelect={handleProductSelect}
            />
            <div className='h-4'/>
            </>
          ))}
        </ul>
              <div className='pb-4 gap-2 flex flex-col'>

          <div className='flex flex-col'>
        <h2 className="text-xl pb-1 pt-2 font-semibold">Contact Information</h2>
        <p className='text-base pb-1'>Please enter your name, email and details of the vending machine so that we can keep you updated.</p>
        </div>
                    <input
            type="text"
            name="name"
            placeholder="Name"
            className="p-2 border border-black w-full rounded-sm"
            onChange={handleInputChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            className="p-2 border border-black w-full rounded-sm"
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="phone"
            placeholder="Phone Number (optional)"
            className="p-2 border border-black w-full rounded-sm"
            onChange={handleInputChange}
            required
          />
          <input
            type="text"
            name="machine"
            placeholder="Machine name, number or location"
            className="p-2 border border-black w-full rounded-sm"
            onChange={handleInputChange}
          />

<div className='flex flex-col'>
<h2 className="text-xl pb-1 pt-4 font-semibold">Additional Notes</h2>
        <p className='text-base pb-1'>Please include any special requests, queries or product requests that are not included in the list above.</p>

        <textarea
          type="text"
          name="notes"
          placeholder="Any notes or special requests, such as item removal etc."
          // make it 3 line heigh
          className="p-2 border border-black w-full rounded-sm min0"
          onChange={handleInputChange}
        />
        </div>
      </div>
      </div>




      {/* Call to Action Button */}
      <div className="mt-4 p-4 w-full pb-20">
        <button
          type="submit"
          onClick={sendEmail}
          className="bg-xvend-yellow text-black font-bold w-full border border-black rounded-sm py-2 px-4"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Sending...' : 'Submit Request'}
        </button>
        {isSubmitted && <p className="mt-4 text-green-500">Thank you for your request. We will be in contact very soon!</p>}
        {errorMessage && <p className="mt-4 text-red-500">{errorMessage}</p>}
      </div>
    </div>
  );
}

export default App;

