export const initialCategories = [
    {
      name: 'Soft Drinks',
      products: [
        { name: 'Coca Cola 375ml can', cost: 2.60, image: '/products/coca_cola_375ml_can.png', selected: false },
        { name: 'Coca Cola 600ml bottle', cost: 4.80, image: '/products/coca_cola_600ml_bottle.png', selected: false },
        { name: 'Coca Cola No Sugar 375ml can', cost: 2.60, image: '/products/coca_cola_no_sugar_375ml_can.png', selected: false },
        { name: 'Coca Cola No Sugar 600ml bottle', cost: 4.80, image: '/products/coca_cola_no_sugar_600ml_bottle.png', selected: false },
        { name: 'Diet Coca Cola 375ml can', cost: 2.60, image: '/products/diet_coca_cola_375ml_can.png', selected: false },
        { name: 'Diet Coca Cola No Sugar 600ml bottle', cost: 4.80, image: '/products/diet_coca_cola_no_sugar_600ml_bottle.png', selected: false },
        { name: 'Vanilla Coca Cola 375ml can', cost: 2.60, image: '/products/vanilla_coca_cola_375ml_can.png', selected: false },
        { name: 'Vanilla Coca Cola 600ml bottle', cost: 4.80, image: '/products/vanilla_coca_cola_600ml_bottle.png', selected: false },
        { name: 'Vanilla No Sugar Coca Cola 375ml can', cost: 2.60, image: '/products/vanilla_no_sugar_coca_cola_375ml_can.png', selected: false },
        { name: 'Vanilla No Sugar Coca Cola 600ml bottle', cost: 4.80, image: '/products/vanilla_no_sugar_coca_cola_600ml_bottle.png', selected: false },
        { name: 'Pepsi 375ml can', cost: 2.60, image: '/products/pepsi_375ml_can.png', selected: false },
        { name: 'Pepsi Max 375ml can', cost: 2.60, image: '/products/pepsi_max_375ml_can.png', selected: false },
        { name: 'Pepsi Max 600ml bottle', cost: 4.80, image: '/products/pepsi_max_600ml_bottle.png', selected: false },
        { name: 'Sprite 375ml can', cost: 2.60, image: '/products/sprite_375ml_can.png', selected: false },
        { name: '7up 375ml can', cost: 2.60, image: '/products/7up_375ml_can.png', selected: false },
        { name: 'Fanta 375ml can', cost: 2.60, image: '/products/fanta_375ml_can.png', selected: false },
        { name: 'Raspberry Fanta 600ml bottle', cost: 4.80, image: '/products/raspberry_fanta_600ml_bottle.png', selected: false },
        { name: 'Sunkist 375ml can', cost: 2.60, image: '/products/sunkist_375ml_can.png', selected: false },
        { name: 'Sunkist Zero 375ml can', cost: 2.60, image: '/products/sunkist_zero_375ml_can.png', selected: false },
        { name: 'Solo 375ml can', cost: 2.60, image: '/products/solo_375ml_can.png', selected: false },
        { name: 'Solo Zero 375ml can', cost: 2.60, image: '/products/solo_zero_375ml_can.png', selected: false },
        { name: 'Solo 600ml bottle', cost: 4.80, image: '/products/solo_600ml_bottle.png', selected: false },
        { name: 'Mountain Dew 375ml can', cost: 2.60, image: '/products/mountain_dew_375ml_can.png', selected: false },
        { name: 'Kirks Creaming Soda 375ml can', cost: 2.60, image: '/products/kirks_creaming_soda_375ml_can.png', selected: false },
        { name: 'Kirks Creaming Soda Zero 375ml can', cost: 2.60, image: '/products/kirks_creaming_soda_zero_375ml_can.png', selected: false },
        { name: 'Kirks Gingerbeer 375ml can', cost: 2.60, image: '/products/kirks_gingerbeer_375ml_can.png', selected: false },
        { name: 'Kirks Pasito 375ml can', cost: 2.60, image: '/products/kirks_pasito_375ml_can.png', selected: false },
        { name: 'Kirks Pasito 600ml bottle', cost: 4.80, image: '/products/kirks_pasito_600ml_bottle.png', selected: false },
        { name: 'Kirks Pasito Sugarfree 375ml can', cost: 2.60, image: '/products/kirks_pasito_sugarfree_375ml_can.png', selected: false },
        { name: 'Kirks Lemon Squash 375ml can', cost: 2.60, image: '/products/kirks_lemon_squash_375ml_can.png', selected: false },
        { name: 'L&P Cola 440ml can', cost: 4.20, image: '/products/lp_cola_440ml_can.png', selected: false },
      ]
    },
    {
      name: 'Waters',
      products: [
        { name: 'Mt Franklin 600ml water bottle', cost: 2.70, image: '/products/mt_franklin_600ml_water_bottle.png', selected: false },
        { name: 'Mt Franklin Lightly Sparkling Mineral Water 450ml bottle', cost: 3.50, image: '/products/mt_franklin_lightly_sparkling_450ml_bottle.png', selected: false },
        { name: 'Nu Pure Water 600ml bottle', cost: 2.50, image: '/products/nu_pure_water_600ml_bottle.png', selected: false },
        { name: 'Nutri Water Endurance 575ml bottle', cost: 4.60, image: '/products/nutri_water_endurance_575ml_bottle.png', selected: false },
        { name: 'Nutri Water A Game Strawberry 575ml bottle', cost: 4.60, image: '/products/nutri_water_a_game_strawberry_575ml_bottle.png', selected: false },
        { name: 'Nutri Water Focus Black Goji 575ml bottle', cost: 4.60, image: '/products/nutri_water_focus_black_goji_575ml_bottle.png', selected: false },
        { name: 'Nutri Water Energy Passionfruit/ Lime 575ml bottle', cost: 4.60, image: '/products/nutri_water_energy_passionfruit_lime_575ml_bottle.png', selected: false },
        { name: 'Waterfords Orange Passion 473ml bottle', cost: 3.80, image: '/products/waterfords_orange_passion_473ml_bottle.png', selected: false },
        { name: 'JT’s Coconut Essence Tropical Water 490ml can', cost: 4.40, image: '/products/jts_coconut_essence_tropical_490ml_can.png', selected: false },
        { name: 'JT’s Coconut Essence Pure Water 510ml can', cost: 4.40, image: '/products/jts_coconut_essence_pure_510ml_can.png', selected: false },
        { name: 'Schweppes Infused Lime Sparkling Water 375ml can', cost: 2.60, image: '/products/schweppes_infused_lime_sparkling_375ml_can.png', selected: false },
        { name: 'Schweppes Infused Blood Orange & Mango Water 375ml can', cost: 2.60, image: '/products/schweppes_infused_blood_orange_mango_375ml_can.png', selected: false },
        { name: 'Bare Naked Sparkling Mineral Water 355ml can - Lemon Lime', cost: 2.70, image: '/products/bare_naked_lemon_lime_355ml_can.png', selected: false },
        { name: 'Bare Naked Sparkling Mineral Water 355ml can - Pine Coconut', cost: 2.70, image: '/products/bare_naked_pine_coconut_355ml_can.png', selected: false },
        { name: 'Bare Naked Sparkling Mineral Water 355ml can - Watermelon', cost: 2.70, image: '/products/bare_naked_watermelon_355ml_can.png', selected: false }
      ]
    },
    {
      name: 'Iced Teas & Juice',
      products: [
        { name: 'Lipton Peach Iced Tea 500ml bottle', cost: 4.20, image: '/products/lipton_peach_iced_tea_500ml_bottle.png', selected: false },
        { name: 'Lipton Lemon Iced Tea 500ml bottle', cost: 4.20, image: '/products/lipton_lemon_iced_tea_500ml_bottle.png', selected: false },
        { name: 'Lipton Sugarfree Peach Iced Tea 500ml bottle', cost: 4.20, image: '/products/lipton_sugarfree_peach_iced_tea_500ml_bottle.png', selected: false },
        { name: 'Ribena 500ml bottle', cost: 4.50, image: '/products/ribena_500ml_bottle.png', selected: false },
        { name: 'Impressed Orange Juice 425ml', cost: 4.30, image: '/products/impressed_orange_juice_425ml.png', selected: false },
        { name: 'Impressed Apple Juice 425ml', cost: 4.30, image: '/products/impressed_apple_juice_425ml.png', selected: false },
        { name: 'Glee Bubblegum Grape Sparkling Juice 250ml can', cost: 2.60, image: '/products/glee_bubblegum_grape_sparkling_juice_250ml_can.png', selected: false },
        { name: 'Glee Watermelon Sour Sparkling Juice 250ml can', cost: 2.60, image: '/products/glee_watermelon_sour_sparkling_juice_250ml_can.png', selected: false },
        { name: 'Glee Tropical Treat Sparkling Juice 250ml can', cost: 2.60, image: '/products/glee_tropical_treat_sparkling_juice_250ml_can.png', selected: false }
      ]
    },
    {
      name: 'Milk & Coffee Drinks',
      products: [
        { name: 'BOSS Caramel Latte 237ml can', cost: 4.60, image: '/products/boss_caramel_latte_237ml_can.png', selected: false },
        { name: 'BOSS Double Shot Latte 237ml can', cost: 4.60, image: '/products/boss_double_shot_latte_237ml_can.png', selected: false },
        { name: 'BOSS Long Black 237ml can', cost: 4.60, image: '/products/boss_long_black_237ml_can.png', selected: false },
        { name: 'Allpress Espresso 240ml can', cost: 4.60, image: '/products/allpress_espresso_240ml_can.png', selected: false },
        { name: 'Allpress Espresso Iced Latte 240ml can', cost: 4.60, image: '/products/allpress_espresso_iced_latte_240ml_can.png', selected: false },
        { name: 'Allpress Espresso Iced Mocha 240ml can', cost: 4.60, image: '/products/allpress_espresso_iced_mocha_240ml_can.png', selected: false },
        { name: 'Nippy\'s Iced Chocolate Milk 500ml bottle', cost: 5.60, image: '/products/nippys_iced_chocolate_milk_500ml_bottle.png', selected: false },
        { name: 'Nippy\'s Iced Honeycomb Milk 500ml bottle', cost: 5.60, image: '/products/nippys_iced_honeycomb_milk_500ml_bottle.png', selected: false },
        { name: 'Nippy\'s Iced Strawberry Milk 500ml bottle', cost: 5.60, image: '/products/nippys_iced_strawberry_milk_500ml_bottle.png', selected: false },
        { name: 'Nippy\'s Iced Coffee Milk 500ml bottle', cost: 5.60, image: '/products/nippys_iced_coffee_milk_500ml_bottle.png', selected: false },
        { name: 'OAK Chocolate Milk 500ml bottle', cost: 5.60, image: '/products/oak_chocolate_milk_500ml_bottle.png', selected: false },
        { name: 'OAK Iced Coffee Milk 500ml bottle', cost: 5.60, image: '/products/oak_iced_coffee_milk_500ml_bottle.png', selected: false },
        { name: 'Ice Break Iced Coffee 500ml bottle', cost: 5.70, image: '/products/ice_break_iced_coffee_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Protein Energize Choc Hit 500ml bottle', cost: 5.70, image: '/products/up_and_go_protein_energize_choc_hit_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Protein Energize Vanilla 500ml bottle', cost: 5.70, image: '/products/up_and_go_protein_energize_vanilla_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Protein Energize Strawberry 500ml bottle', cost: 5.70, image: '/products/up_and_go_protein_energize_strawberry_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Liquid Breakfast Choc Ice 500ml bottle', cost: 5.70, image: '/products/up_and_go_liquid_breakfast_choc_ice_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Liquid Breakfast Vanilla Ice 500ml bottle', cost: 5.70, image: '/products/up_and_go_liquid_breakfast_vanilla_ice_500ml_bottle.png', selected: false },
        { name: 'Rokeby Farms Dutch Chocolate protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_dutch_chocolate_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Double Espresso protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_double_espresso_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Vanilla Wafer protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_vanilla_wafer_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Choc Honeycomb protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_choc_honeycomb_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Banana Honey & Cinnamon protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_banana_honey_cinnamon_smoothie_425ml.png', selected: false }
      ]
    },
    {
      name: 'Energy Drinks',
      products: [
        { name: 'V Green 250ml can', cost: 3.90, image: '/products/v_green_250ml_can.png', selected: false },
        { name: 'V Blue 250ml can', cost: 3.90, image: '/products/v_blue_250ml_can.png', selected: false },
        { name: 'V Green Sugarfree 250ml can', cost: 5.30, image: '/products/v_green_sugarfree_250ml_can.png', selected: false },
        { name: 'V Blue Sugarfree 250ml can', cost: 3.90, image: '/products/v_blue_sugarfree_250ml_can.png', selected: false },
        { name: 'V Refresh Pineapple & Watermelon 250ml can', cost: 3.90, image: '/products/v_refresh_pineapple_watermelon_250ml_can.png', selected: false },
        { name: 'V Refresh Green Apple Lemonade 250ml can', cost: 3.90, image: '/products/v_refresh_green_apple_lemonade_250ml_can.png', selected: false },
        { name: 'V Refresh Blackcurrant & Yuzu 250ml can', cost: 3.90, image: '/products/v_refresh_blackcurrant_yuzu_250ml_can.png', selected: false },
        { name: 'V Black 250ml can', cost: 3.90, image: '/products/v_black_250ml_can.png', selected: false },
        { name: 'V Green 500ml can', cost: 5.30, image: '/products/v_green_500ml_can.png', selected: false },
        { name: 'V Blue 500ml can', cost: 5.30, image: '/products/v_blue_500ml_can.png', selected: false },
        { name: 'V Refresh Pineapple & Watermelon 500ml can', cost: 5.30, image: '/products/v_refresh_pineapple_watermelon_500ml_can.png', selected: false },
        { name: 'V Refresh Green Apple Lemonade 500ml can', cost: 5.30, image: '/products/v_refresh_green_apple_lemonade_500ml_can.png', selected: false },
        { name: 'V Refresh Blackcurrant & Yuzu 500ml can', cost: 5.30, image: '/products/v_refresh_blackcurrant_yuzu_500ml_can.png', selected: false },
        { name: 'V Raspberry Lemonade 500ml can', cost: 5.30, image: '/products/v_raspberry_lemonade_500ml_can.png', selected: false },
        { name: 'V Black 500ml can', cost: 5.30, image: '/products/v_black_500ml_can.png', selected: false },
        { name: 'Red Bull 250ml can', cost: 3.90, image: '/products/red_bull_250ml_can.png', selected: false },
        { name: 'Red Bull Sugarfree 250ml can', cost: 3.90, image: '/products/red_bull_sugarfree_250ml_can.png', selected: false },
        { name: 'Red Bull 473ml can', cost: 5.70, image: '/products/red_bull_473ml_can.png', selected: false },
        { name: 'Red Bull Sugarfree 473ml can', cost: 5.70, image: '/products/red_bull_sugarfree_473ml_can.png', selected: false },
        { name: 'Red Bull Zero 473ml can', cost: 5.70, image: '/products/red_bull_zero_473ml_can.png', selected: false },
        { name: 'Rockstar Guava 500ml can', cost: 5.30, image: '/products/rockstar_guava_500ml_can.png', selected: false },
        { name: 'Monster Green 500ml can', cost: 4.90, image: '/products/monster_green_500ml_can.png', selected: false },
        { name: 'Monster Rosa 500ml can', cost: 4.90, image: '/products/monster_rosa_500ml_can.png', selected: false },
        { name: 'Monster Mango Loco 500ml can', cost: 4.90, image: '/products/monster_mango_loco_500ml_can.png', selected: false },
        { name: 'Monster Pipeline Punch 500ml can', cost: 4.90, image: '/products/monster_pipeline_punch_500ml_can.png', selected: false },
        { name: 'Monster Ultra Sugarfree 500ml can', cost: 4.90, image: '/products/monster_ultra_sugarfree_500ml_can.png', selected: false },
        { name: 'Monster Ultra Gold 500ml can', cost: 4.90, image: '/products/monster_ultra_gold_500ml_can.png', selected: false },
        { name: 'Monster Ultra Paradise 500ml can', cost: 4.90, image: '/products/monster_ultra_paradise_500ml_can.png', selected: false },
        { name: 'Mother 500ml can', cost: 4.90, image: '/products/mother_500ml_can.png', selected: false },
        { name: 'Mother Passion 500ml can', cost: 4.90, image: '/products/mother_passion_500ml_can.png', selected: false },
        { name: 'Kamikaze Hawaiian Splice 500ml can', cost: 5.60, image: '/products/kamikaze_hawaiian_splice_500ml_can.png', selected: false },
        { name: 'Kamikaze (Mango) Slushie Madness 500ml can', cost: 5.60, image: '/products/kamikaze_mango_slushie_madness_500ml_can.png', selected: false },
        { name: 'Kamikaze Creaming Soda 500ml can', cost: 5.60, image: '/products/kamikaze_creaming_soda_500ml_can.png', selected: false },
        { name: 'BSC Energy Drink Ice Blast 500ml can', cost: 5.60, image: '/products/bsc_energy_drink_ice_blast_500ml_can.png', selected: false },
        { name: 'BSC Energy Drink Berry Burst 500ml can', cost: 5.60, image: '/products/bsc_energy_drink_berry_burst_500ml_can.png', selected: false },
        { name: 'BSC Energy Drink Green Lime Crush 500ml can', cost: 5.60, image: '/products/bsc_energy_drink_green_lime_crush_500ml_can.png', selected: false },
        { name: 'BSC Protein Water Green Apple 355ml can', cost: 5.60, image: '/products/bsc-protein-water-green-apple.png', selected: false },
        { name: 'BSC Protein Water Musk Sticks 355ml can', cost: 5.60, image: '/products/bsc-protein-water-musk-sticks.png', selected: false },
        { name: 'BSC Protein Water Pina Colada 355ml can', cost: 5.60, image: '/products/bsc-protein-water-pina-colada.jpeg', selected: false },
        { name: 'OxyShred Passionfruit 355ml can', cost: 5.80, image: '/products/oxyshred_passionfruit_355ml_can.png', selected: false },
        { name: 'OxyShred Kiwi Strawberry 355ml can', cost: 5.80, image: '/products/oxyshred_kiwi_strawberry_355ml_can.png', selected: false }
      ]
    },
    {
      name: 'Sports Drinks',
      products: [
        { name: 'Powerade Mountain Blast 600ml bottle', cost: 4.70, image: '/products/powerade_mountain_blast_600ml_bottle.png', selected: false },
        { name: 'Powerade Berry Ice 600ml bottle', cost: 4.70, image: '/products/powerade_berry_ice_600ml_bottle.png', selected: false },
        { name: 'Gatorade Lemon Lime 600ml bottle', cost: 4.70, image: '/products/gatorade_lemon_lime_600ml_bottle.png', selected: false },
        { name: 'Gatorade Grape 600ml bottle', cost: 4.70, image: '/products/gatorade_grape_600ml_bottle.png', selected: false },
        { name: 'Gatorade Green Apple 600ml bottle', cost: 4.70, image: '/products/gatorade_green_apple_600ml_bottle.png', selected: false },
        { name: 'Gatorade No Sugar Grape 600ml bottle', cost: 4.70, image: '/products/gatorade_no_sugar_grape_600ml_bottle.png', selected: false },
        { name: 'Gatorade Glacier Freeze No Sugar 600ml bottle', cost: 4.70, image: '/products/gatorade_glacier_freeze_no_sugar_600ml_bottle.png', selected: false },
        { name: 'Maximus Blackcurrant 1L bottle', cost: 5.30, image: '/products/maximus_blackcurrant_1l_bottle.png', selected: false },
        { name: 'Maximus Blue 1L bottle', cost: 5.30, image: '/products/maximus_blue_1l_bottle.png', selected: false },
        { name: 'Maximus Red 1L bottle', cost: 5.30, image: '/products/maximus_red_1l_bottle.png', selected: false },
        { name: 'Maximus Lime 1L bottle', cost: 5.30, image: '/products/maximus_lime_1l_bottle.png', selected: false },
        { name: 'Maximus Grape 1L bottle', cost: 5.30, image: '/products/maximus_grape_1l_bottle.png', selected: false },
        { name: 'Maximus Mango & Passion 1L bottle', cost: 5.30, image: '/products/maximus_mango_passion_1l_bottle.png', selected: false },
        { name: 'Maximus Pine Lime 1L bottle', cost: 5.30, image: '/products/maximus_pine_lime_1l_bottle.png', selected: false },
        { name: 'Maximus Lemonade 1L bottle', cost: 5.30, image: '/products/maximus_lemonade_1l_bottle.png', selected: false },
        { name: 'Maximus Zero Sugar Blue 1L bottle', cost: 5.30, image: '/products/maximus_zero_sugar_blue_1l_bottle.png', selected: false },
        { name: 'Maximus Zero Sugar Grape 1L bottle', cost: 5.30, image: '/products/maximus-zero-sugar-grape.png', selected: false },
        { name: 'G Force Apple & Blackcurrant 750ml bottle', cost: 5.00, image: '/products/g_force_apple_blackcurrant_750ml_bottle.png', selected: false },
        { name: 'G Force Orange & Mandarin 750ml bottle', cost: 5.00, image: '/products/g_force_orange_mandarin_750ml_bottle.png', selected: false },
        { name: 'G Force Raspberry & Blueberry 750ml bottle', cost: 5.00, image: '/products/g_force_raspberry_blueberry_750ml_bottle.png', selected: false },
        { name: 'G Force Mango & Pineapple 750ml bottle', cost: 5.00, image: '/products/g_force_mango_pineapple_750ml_bottle.png', selected: false },
        { name: 'Cocobella Hydrate Raspberry 500ml bottle', cost: 4.60, image: '/products/cocobella_hydrate_raspberry_500ml_bottle.png', selected: false },
        { name: 'Cocobella Hydrate Tropical 500ml bottle', cost: 4.60, image: '/products/cocobella_hydrate_tropical_500ml_bottle.png', selected: false }
      ]
    },
    {
      name: 'Protein Drinks',
      products: [
        { name: 'Sanitarium Up & Go Protein Energize Choc Hit 500ml bottle', cost: 5.70, image: '/products/up_and_go_protein_energize_choc_hit_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Protein Energize Vanilla 500ml bottle', cost: 5.70, image: '/products/up_and_go_protein_energize_vanilla_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Protein Energize Strawberry 500ml bottle', cost: 5.70, image: '/products/up_and_go_protein_energize_strawberry_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Liquid Breakfast Choc Ice 500ml bottle', cost: 5.70, image: '/products/up_and_go_liquid_breakfast_choc_ice_500ml_bottle.png', selected: false },
        { name: 'Sanitarium Up & Go Liquid Breakfast Vanilla Ice 500ml bottle', cost: 5.70, image: '/products/up_and_go_liquid_breakfast_vanilla_ice_500ml_bottle.png', selected: false },
        { name: 'Rokeby Farms Dutch Chocolate protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_dutch_chocolate_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Double Espresso protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_double_espresso_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Vanilla Wafer protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_vanilla_wafer_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Choc Honeycomb protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_choc_honeycomb_smoothie_425ml.png', selected: false },
        { name: 'Rokeby Farms Banana Honey & Cinnamon protein smoothie 425ml', cost: 5.80, image: '/products/rokeby_farms_banana_honey_cinnamon_smoothie_425ml.png', selected: false },
        { name: 'Musashi High Protein Chocolate Shake 375ml bottle', cost: 6.60, image: '/products/musashi_high_protein_chocolate_shake_375ml_bottle.png', selected: false }
      ]
    },
    {
      name: 'American Products',
      products: [
        { name: 'Van Holten Hot Mama Pickle', cost: 6.50, image: '/products/van_holten_hot_mama_pickle.png', selected: false },
        { name: 'Van Holten Big Papa Pickle', cost: 6.50, image: '/products/van_holten_big_papa_pickle.png', selected: false }
      ]
    },
    {
      name: 'Chips',
      products: [
        { name: 'Smiths Salt & Vinegar 45g bag', cost: 2.70, image: '/products/smiths_salt_vinegar_45g_bag.png', selected: false },
        { name: 'Smiths Original 45g bag', cost: 2.70, image: '/products/smiths_original_45g_bag.png', selected: false },
        { name: 'Smiths Chicken 45g bag', cost: 2.70, image: '/products/smiths_chicken_45g_bag.png', selected: false },
        { name: 'Smiths Cheese & Onion 45g bag', cost: 2.70, image: '/products/smiths_cheese_onion_45g_bag.png', selected: false },
        { name: 'Red Rock Sweet Chilli & Sour Cream 45g bag', cost: 2.70, image: '/products/red_rock_sweet_chilli_sour_cream_45g_bag.png', selected: false },
        { name: 'Red Rock Honey Soy Chicken 45g bag', cost: 2.70, image: '/products/red_rock_honey_soy_chicken_45g_bag.png', selected: false },
        { name: 'Twisties 45g bag', cost: 2.70, image: '/products/twisties_45g_bag.png', selected: false },
        { name: 'CC’s Tasty Cheese 45g bag', cost: 2.70, image: '/products/ccs_tasty_cheese_45g_bag.png', selected: false },
        { name: 'Samboy Atomic Tomato 45g bag', cost: 2.70, image: '/products/samboy_atomic_tomato_45g_bag.png', selected: false },
        { name: 'Kettle Honey Soy Chicken 45g bag', cost: 2.70, image: '/products/kettle_honey_soy_chicken_45g_bag.png', selected: false },
        { name: 'Burger Rings 45g bag', cost: 2.70, image: '/products/burger_rings_45g_bag.png', selected: false },
        { name: 'Vege Chips BBQ 50g bag', cost: 2.70, image: '/products/vege_chips_bbq_50g_bag.png', selected: false },
        { name: 'Vege Chips Sweet & Sour 50g bag', cost: 2.70, image: '/products/vege_chips_sweet_sour_50g_bag.png', selected: false },
        { name: 'Vege Chips Natural 50g bag', cost: 2.70, image: '/products/vege_chips_natural_50g_bag.png', selected: false },
        { name: 'Vege Chips Salt & Vinegar 50g bag', cost: 2.70, image: '/products/vege_chips_salt_vinegar_50g_bag.png', selected: false },
        { name: 'Vege Chips Chicken 50g bag', cost: 2.70, image: '/products/vege_chips_chicken_50g_bag.png', selected: false },
        { name: 'Piranha BBQ Vege Cracker 45g bag', cost: 2.70, image: '/products/piranha_bbq_vege_cracker_45g_bag.png', selected: false },
        { name: 'Piranha Balsamic Vinegar & Sea Salt Vege Cracker 45g bag', cost: 2.70, image: '/products/piranha_balsamic_vinegar_sea_salt_45g_bag.png', selected: false },
        { name: 'Piranha Balsamic Chilli Lime Vege Cracker 45g bag', cost: 2.70, image: '/products/piranha_balsamic_chilli_lime_45g_bag.png', selected: false },
        { name: 'Tyrrells Salt & Vinegar 45g bag', cost: 2.70, image: '/products/tyrrells_salt_vinegar_45g_bag.png', selected: false },
        { name: 'Tyrrells Cheddar Cheese & Chives 45g bag', cost: 2.70, image: '/products/tyrrells_cheddar_cheese_chives_45g_bag.png', selected: false },
        { name: 'Tyrrells Sea Salt 45g bag', cost: 2.70, image: '/products/tyrrells_sea_salt_45g_bag.png', selected: false },
        { name: 'Thins Light & Tangy 45g bag', cost: 2.70, image: '/products/thins_light_tangy_45g_bag.png', selected: false },
        { name: 'Cheetos Cheese & Bacon Balls 45g bag', cost: 2.70, image: '/products/cheetos_cheese_bacon_balls_45g_bag.png', selected: false },
        { name: 'Cheezels 45g bag', cost: 2.70, image: '/products/cheezels_45g_bag.png', selected: false },
        { name: 'Grainwaves Sour Cream & Chives 40g bag', cost: 2.70, image: '/products/grainwaves_sour_cream_chives_40g_bag.png', selected: false }
      ]
    },
    {
      name: 'Salty Snacks',
      products: [
        { name: 'Pirahana Snack mix 80g bag', cost: 3.80, image: '/products/pirahana_snack_mix_80g_bag.png', selected: false },
        { name: 'Shapes BBQ 70g bag', cost: 3.80, image: '/products/shapes_bbq_70g_bag.png', selected: false },
        { name: 'Shapes Pizza 70g bag', cost: 3.80, image: '/products/shapes_pizza_70g_bag.png', selected: false },
        { name: 'Shapes Cheese & Bacon 70g bag', cost: 3.80, image: '/products/shapes_cheese_bacon_70g_bag.png', selected: false },
        { name: 'Cobs Sea Salt Popcorn 20g', cost: 2.70, image: '/products/cobs_sea_salt_popcorn_20g.png', selected: false },
        { name: 'Cobs Sweet & Salty Popcorn 20g', cost: 2.70, image: '/products/cobs_sweet_salty_popcorn_20g.png', selected: false }
      ]
    },
    {
      name: 'Cookies',
      products: [
        { name: 'Country Style White Choc & Macadamia Cookie 70g', cost: 3.60, image: '/products/country_style_white_choc_macadamia_cookie_70g.png', selected: false },
        { name: 'Country Style Chocolate Chip Cookie 70g', cost: 3.60, image: '/products/country_style_chocolate_chip_cookie_70g.png', selected: false },
        { name: 'Country Style Triple Chocolate Cookie 70g', cost: 3.60, image: '/products/country_style_triple_chocolate_cookie_70g.png', selected: false },
        { name: 'Byron Bay Bay Dottie Cookie Original or Gluten free', cost: 4.30, image: '/products/byron_bay_dottie_cookie.png', selected: false }
      ]
    },
    {
      name: 'Slices & Small Snacks',
      products: [
        { name: 'John West Tuna Lunch Kit with Sweet Corn & Mayo 108g box', cost: 5.50, image: '/products/john_west_tuna_lunch_kit_sweet_corn_mayo_108g_box.png', selected: false },
        { name: 'Sirena Chicken Pad Thai snack bowl 170g', cost: 6.80, image: '/products/sirena-pad-thai.jpeg', selected: false },
        { name: 'Sirena Italian Style Salad & Tuna snack bowl 170g', cost: 6.80, image: '/products/sirena_italian_salad_tuna_snack_bowl_170g.png', selected: false },
        { name: 'Sirena Fried rice with Chicken snack bowl 190g', cost: 6.80, image: '/products/sirena_fried_rice_chicken_snack_bowl_190g.png', selected: false },
        { name: 'Mainland On the Go Tasty Cheese & Crackers 50g box', cost: 4.30, image: '/products/mainland_cheese_crackers_50g_box.png', selected: false },
        { name: 'Carmans Golden Oat & Coconut Slice 70g', cost: 3.80, image: '/products/carmans_golden_oat_coconut_slice_70g.png', selected: false },
        { name: 'Carmans Belgian Choc Oat Slice 70g', cost: 3.80, image: '/products/carmans_belgian_choc_oat_slice_70g.png', selected: false },
        { name: 'Carmans Salted Dark Chocolate Protein bar 40g', cost: 3.60, image: '/products/carmans_salted_dark_chocolate_protein_bar_40g.png', selected: false },
        { name: 'Carmans Almond, Hazelnut & Vanilla Nut bar 45g', cost: 3.60, image: '/products/carmans_almond_hazelnut_vanilla_nut_bar_45g.png', selected: false },
        { name: 'JC’s Jackaroo Trail Mix 45g bag', cost: 3.00, image: '/products/jcs_jackaroo_trail_mix_45g_bag.png', selected: false },
        { name: 'JC’s Healthy Trail Mix 45g bag', cost: 3.00, image: '/products/jcs_healthy_trail_mix_45g_bag.png', selected: false },
        { name: 'Brookfarm Explorer Brothers Blend Trail mix 35g', cost: 3.80, image: '/products/brookfarm_explorer_trail_mix_35g.png', selected: false },
        { name: 'Primo Salami Trios (cracker, cheese & salami)', cost: 5.80, image: '/products/primo_salami_trios.png', selected: false },
        { name: 'Primo Shapes BBQ Lovers (cracker, cheese, meat)', cost: 5.80, image: '/products/primo_shapes_bbq_lovers.png', selected: false },
        { name: 'Primo Shapes Pizza Lovers (cracker, cheese, meat)', cost: 5.80, image: '/products/primo_shapes_pizza_lovers.png', selected: false },
        { name: 'Maggi Noodle Cups - Chicken', cost: 3.60, image: '/products/maggi_noodle_cups_chicken.png', selected: false },
        { name: 'Maggi Noodle Cups - Beef', cost: 3.60, image: '/products/maggi_noodle_cups_beef.png', selected: false },
        { name: 'Indo Mie - Mi goreng noodle cups', cost: 3.60, image: '/products/indo_mie_mi_goreng_noodle_cups.png', selected: false },
        { name: 'Indo Mie - Mi goreng Hot & Spicy noodle cups', cost: 3.60, image: '/products/indo_mie_mi_goreng_hot_spicy_noodle_cups.png', selected: false },
        { name: 'Lian PhoBo Noodle Bowl (beef)', cost: 4.20, image: '/products/lian_phobo_noodle_bowl_beef.png', selected: false },
        { name: 'Ichiban Tonkotsu Ramen 185g', cost: 7.00, image: '/products/ichiban_tonkotsu_ramen_185g.png', selected: false },
        { name: 'Hart & Soul Butter Chicken 250g', cost: 6.50, image: '/products/hart_soul_butter_chicken_250g.png', selected: false }
      ]
    },
    {
      name: 'Jerky & Meat',
      products: [
        { name: 'Byron Bay Jerky Smoking Hot Jerky 40g bag', cost: 6.00, image: '/products/byron_bay_smoking_hot_jerky_40g_bag.png', selected: false },
        { name: 'Byron Bay Jerky Teriyaki Jerky 40g bag', cost: 6.00, image: '/products/byron_bay_teriyaki_jerky_40g_bag.png', selected: false },
        { name: 'Nobby’s Bacon Bites 40g', cost: 3.90, image: '/products/nobbys_bacon_bites_40g.png', selected: false }
      ]
    },
    {
      name: 'Chocolates',
      products: [
        { name: 'Nestle Chockito 55g bar', cost: 2.90, image: '/products/nestle_chockito_55g_bar.png', selected: false },
        { name: 'Nestle Kit Kat Chunky 45g bar', cost: 2.90, image: '/products/nestle_kit_kat_chunky_45g_bar.png', selected: false },
        { name: 'Nestle Kit Kat Chunky Caramel 45g bar', cost: 2.90, image: '/products/nestle_kit_kat_chunky_caramel_45g_bar.png', selected: false },
        { name: 'Nestle Kit Kat Chunky Aero Mint 45g bar', cost: 2.90, image: '/products/nestle_kit_kat_chunky_aero_mint_45g_bar.png', selected: false },
        { name: 'Nestle Kit Kat Chunky Cookie Dough 45g bar', cost: 2.90, image: '/products/nestle_kit_kat_chunky_cookie_dough_45g_bar.png', selected: false },
        { name: 'Whittakers Peanut Slab 50g bar', cost: 2.90, image: '/products/whittakers_peanut_slab_50g_bar.png', selected: false },
        { name: 'Whittakers Coconut Slab 50g bar', cost: 2.90, image: '/products/whittakers_coconut_slab_50g_bar.png', selected: false },
        { name: 'Cadbury Boost 60g bar', cost: 2.90, image: '/products/cadbury_boost_60g_bar.png', selected: false },
        { name: 'Cadbury Turkish Delight 55g bar', cost: 2.90, image: '/products/cadbury_turkish_delight_55g_bar.png', selected: false },
        { name: 'Cadbury Dairy Milk 50g bar', cost: 2.90, image: '/products/cadbury_dairy_milk_50g_bar.png', selected: false },
        { name: 'Cadbury Caramilk 45g bar', cost: 2.90, image: '/products/cadbury_caramilk_45g_bar.png', selected: false },
        { name: 'Cadbury Caramello 55g bar', cost: 2.90, image: '/products/cadbury_caramello_55g_bar.png', selected: false },
        { name: 'Cadbury Dairy Milk Marvellous Creations 50g bar', cost: 2.90, image: '/products/cadbury_dairy_milk_marvellous_creations_50g_bar.png', selected: false },
        { name: 'Cadbury Picnic 46g bar', cost: 2.90, image: '/products/cadbury_picnic_46g_bar.png', selected: false },
        { name: 'Mars Bar 53g bar', cost: 2.90, image: '/products/mars_bar_53g_bar.png', selected: false },
        { name: 'Snickers 50g bar', cost: 2.90, image: '/products/snickers_50g_bar.png', selected: false },
        { name: 'Violet Crumble 100g bag', cost: 6.60, image: '/products/violet_crumble_100g_bag.png', selected: false },
        { name: 'MnM\'s 46g Bag', cost: 2.90, image: '/products/mnms_46g_bag.png', selected: false },
        { name: 'MnM’s Peanut 46g bag', cost: 2.90, image: '/products/mnms_peanut_46g_bag.png', selected: false },
        { name: 'Maltesers 60g box', cost: 3.50, image: '/products/maltesers_60g_box.png', selected: false },
        { name: 'Cherry Ripe 52g bar', cost: 2.90, image: '/products/cherry_ripe_52g_bar.png', selected: false },
        { name: 'Milky Bar 50g bar', cost: 2.90, image: '/products/milky_bar_50g_bar.png', selected: false },
        { name: 'Twix 50g', cost: 2.90, image: '/products/twix_50g.png', selected: false },
        { name: 'Cooks Scorched Peanut 45g bar', cost: 2.90, image: '/products/cooks_scorched_peanut_45g_bar.png', selected: false },
        { name: 'Kinder Bueno 43g', cost: 3.30, image: '/products/kinder_bueno_43g.png', selected: false },
        { name: 'Mars Pods 160g Bag', cost: 6.60, image: '/products/mars_pods_160g_bag.png', selected: false },
        { name: 'Snickers Pods 160g Bag', cost: 6.60, image: '/products/snickers_pods_160g_bag.png', selected: false }
      ]
    },
    {
      name: 'Lollies',
      products: [
        { name: 'Allens Snakes Alive 200g bag', cost: 4.50, image: '/products/allens_snakes_alive_200g_bag.png', selected: false },
        { name: 'Allens Party Mix 190g bag', cost: 4.50, image: '/products/allens_party_mix_190g_bag.png', selected: false },
        { name: 'Natural Confectionery Company Fruity Chews 180g bag', cost: 4.50, image: '/products/natural_confectionery_company_fruity_chews_180g_bag.png', selected: false },
        { name: 'Natural Confectionery Company Sour Squirms 180g bag', cost: 4.50, image: '/products/natural_confectionery_company_sour_squirms_180g_bag.png', selected: false },
        { name: 'Lifesavers Fruit Pastilles 180g Bag', cost: 5.50, image: '/products/lifesavers_fruit_pastilles_180g_bag.png', selected: false },
        { name: 'Zappo Millions Rainbow 75g', cost: 2.80, image: '/products/zappo_millions_rainbow_75g.png', selected: false },
        { name: 'Extra Gum 27g box', cost: 2.80, image: '/products/extra_gum_27g_box.png', selected: false },
        { name: '5Gum Watermelon 32g', cost: 2.80, image: '/products/5gum_watermelon_32g.png', selected: false }
      ]
    },
    {
      name: 'Protein Food',
      products: [
        { name: 'Body Science Soft Bar Double Chocolate 55g', cost: 5.00, image: '/products/body_science_soft_bar_double_chocolate_55g.png', selected: false },
        { name: 'Body Science Soft Bar Banana Chocolate 55g', cost: 5.00, image: '/products/body_science_soft_bar_banana_chocolate_55g.png', selected: false },
        { name: 'Body Science Soft Bar Violet Crumble 55g', cost: 5.00, image: '/products/body_science_soft_bar_violet_crumble_55g.png', selected: false },
        { name: 'Body Science Soft Bar Vanilla Chupa Chups 55g', cost: 5.00, image: '/products/bsc_logo.png', selected: false },
        { name: 'Body Science Soft Bar Choc Vanilla Chupa Chups 55g', cost: 5.00, image: '/products/bsc_logo.png', selected: false },
        { name: 'Body Science Soft Bar Strawberry Dream Chupa Chups 55g', cost: 5.00, image: '/products/bsc_logo.png', selected: false },
        { name: 'Body Science High Protein & Low Carb Cookies Bar 55g', cost: 5.00, image: '/products/body_science_high_protein_low_carb_cookies_bar_55g.png', selected: false },
        { name: 'Body Science Low Carb Chocolate Mousse Bar 55g', cost: 5.00, image: '/products/body_science_low_carb_chocolate_mousse_bar_55g.png', selected: false },
        { name: 'Body Science Low Carb Cookies & Cream Mousse Bar 55g', cost: 5.00, image: '/products/body_science_low_carb_cookies_cream_mousse_bar_55g.png', selected: false }
      ]
    },
    {
      name: 'My Muscle Chef',
      products: [
        { name: 'Chicken Fettuccine Carbonara with Beef Rashers', cost: 12.50, image: '/products/chicken_fettuccine_carbonara_beef_rashers.png', selected: false },
        { name: 'Loaded Mac and Cheese with Beef Rashers', cost: 12.50, image: '/products/loaded_mac_cheese_beef_rashers.png', selected: false },
        { name: 'Chipotle Chicken Burrito Bowl', cost: 12.50, image: '/products/chipotle_chicken_burrito_bowl.png', selected: false },
        { name: 'Beef Ravioli with Napolitana Sauce', cost: 12.50, image: '/products/beef_ravioli_napolitana_sauce.png', selected: false },
        { name: 'Butter Chicken with Saffron Pilaf', cost: 12.50, image: '/products/butter_chicken_saffron_pilaf.png', selected: false },
        { name: 'Chicken and Mushroom Pasta', cost: 12.50, image: '/products/chicken_mushroom_pasta.png', selected: false }
      ]
    },
    {
      name: 'Fresh Food',
      products: [
        { name: 'Croque Monsieur - Ham + Cheese', cost: 8.00, image: '/products/croque_monsieur_ham_cheese.png', selected: false },
        { name: 'Croque Monsieur - Chicken + Bacon + Cheese', cost: 8.00, image: '/products/croque_monsieur_chicken_bacon_cheese.png', selected: false },
        { name: 'Sausage Rolls - Multiple Varieties', cost: 5.50, image: '/products/sausage_rolls_varieties.png', selected: false },
        { name: 'Pies - Multiple Varieties', cost: 5.50, image: '/products/pies_varieties.png', selected: false }
      ]
    },
    {
      name: 'Consumables',
      products: [
        { name: 'Dishwashing Powder', cost: 1.00, image: '/products/dishwashing_powder.png', selected: false },
        { name: 'Laundry Liquid', cost: 2.50, image: '/products/laundry_liquid.png', selected: false },
        { name: 'Dental Kit', cost: 3.00, image: '/products/dental_kit.png', selected: false },
        { name: 'Pack of Pens', cost: 5.50, image: '/products/pack_of_pens.png', selected: false },
        { name: 'Travel Adapter', cost: 15.00, image: '/products/travel_adapter.png', selected: false },
        { name: 'Panadol Optizorb 20 caplets', cost: 8.00, image: '/products/panadol-optizorb.jpg', selected: false },
        { name: 'Nurofen 20 Caplets', cost: 8.00, image: '/products/nurofen-ibruprofen.jpg', selected: false },
        { name: 'USP - Lightning to USB-A 2m Cable', cost: 16.00, image: '/products/usb-a-lightning.png', selected: false },
        { name: 'USP - Lightning to USB-C 2m Cable', cost: 16.00, image: '/products/usb-c-lightning.png', selected: false },
        { name: 'USP - USB-C to USB-A 2m Cable', cost: 16.00, image: '/products/usb-a-usb-c-2m.png', selected: false },
        { name: 'USP - USB-C to USB-C 2m Cable', cost: 16.00, image: '/products/usb-c-usb-c-2m.png', selected: false },
        { name: 'USP - 20W Dual Port Fast Wall Charger', cost: 22.00, image: '/products/20w-wall-adapter.png', selected: false },
        { name: 'USP - Power Bank 10K', cost: 40.00, image: '/products/10k-powerbank.png', selected: false },
        { name: 'USP - True Wireless Headphones', cost: 40.00, image: '/products/usp-tws-headphones.png', selected: false },
      ]
    }
  ];